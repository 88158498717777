exports.components = {
  "component---src-templates-404-jsx": () => import("./../../../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-become-a-partner-jsx": () => import("./../../../src/templates/become-a-partner.jsx" /* webpackChunkName: "component---src-templates-become-a-partner-jsx" */),
  "component---src-templates-blog-author-jsx": () => import("./../../../src/templates/blog-author.jsx" /* webpackChunkName: "component---src-templates-blog-author-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-book-a-demo-jsx": () => import("./../../../src/templates/book-a-demo.jsx" /* webpackChunkName: "component---src-templates-book-a-demo-jsx" */),
  "component---src-templates-build-vs-buy-jsx": () => import("./../../../src/templates/build-vs-buy.jsx" /* webpackChunkName: "component---src-templates-build-vs-buy-jsx" */),
  "component---src-templates-calculator-jsx": () => import("./../../../src/templates/calculator.jsx" /* webpackChunkName: "component---src-templates-calculator-jsx" */),
  "component---src-templates-contact-us-jsx": () => import("./../../../src/templates/contact-us.jsx" /* webpackChunkName: "component---src-templates-contact-us-jsx" */),
  "component---src-templates-customer-center-jsx": () => import("./../../../src/templates/customer-center.jsx" /* webpackChunkName: "component---src-templates-customer-center-jsx" */),
  "component---src-templates-customer-jsx": () => import("./../../../src/templates/customer.jsx" /* webpackChunkName: "component---src-templates-customer-jsx" */),
  "component---src-templates-customers-jsx": () => import("./../../../src/templates/customers.jsx" /* webpackChunkName: "component---src-templates-customers-jsx" */),
  "component---src-templates-data-teams-jsx": () => import("./../../../src/templates/data-teams.jsx" /* webpackChunkName: "component---src-templates-data-teams-jsx" */),
  "component---src-templates-event-post-jsx": () => import("./../../../src/templates/event-post.jsx" /* webpackChunkName: "component---src-templates-event-post-jsx" */),
  "component---src-templates-events-jsx": () => import("./../../../src/templates/events.jsx" /* webpackChunkName: "component---src-templates-events-jsx" */),
  "component---src-templates-fair-billing-policy-jsx": () => import("./../../../src/templates/fair-billing-policy.jsx" /* webpackChunkName: "component---src-templates-fair-billing-policy-jsx" */),
  "component---src-templates-feature-jsx": () => import("./../../../src/templates/feature.jsx" /* webpackChunkName: "component---src-templates-feature-jsx" */),
  "component---src-templates-firebase-jsx": () => import("./../../../src/templates/firebase.jsx" /* webpackChunkName: "component---src-templates-firebase-jsx" */),
  "component---src-templates-for-enterprises-jsx": () => import("./../../../src/templates/for-enterprises.jsx" /* webpackChunkName: "component---src-templates-for-enterprises-jsx" */),
  "component---src-templates-for-startups-jsx": () => import("./../../../src/templates/for-startups.jsx" /* webpackChunkName: "component---src-templates-for-startups-jsx" */),
  "component---src-templates-get-a-demo-jsx": () => import("./../../../src/templates/get-a-demo.jsx" /* webpackChunkName: "component---src-templates-get-a-demo-jsx" */),
  "component---src-templates-home-new-jsx": () => import("./../../../src/templates/home-new.jsx" /* webpackChunkName: "component---src-templates-home-new-jsx" */),
  "component---src-templates-indies-jsx": () => import("./../../../src/templates/indies.jsx" /* webpackChunkName: "component---src-templates-indies-jsx" */),
  "component---src-templates-integration-jsx": () => import("./../../../src/templates/integration.jsx" /* webpackChunkName: "component---src-templates-integration-jsx" */),
  "component---src-templates-integrations-jsx": () => import("./../../../src/templates/integrations.jsx" /* webpackChunkName: "component---src-templates-integrations-jsx" */),
  "component---src-templates-migration-implementation-case-jsx": () => import("./../../../src/templates/migration-implementation-case.jsx" /* webpackChunkName: "component---src-templates-migration-implementation-case-jsx" */),
  "component---src-templates-partners-jsx": () => import("./../../../src/templates/partners.jsx" /* webpackChunkName: "component---src-templates-partners-jsx" */),
  "component---src-templates-paywall-gallery-jsx": () => import("./../../../src/templates/paywall-gallery.jsx" /* webpackChunkName: "component---src-templates-paywall-gallery-jsx" */),
  "component---src-templates-paywalls-jsx": () => import("./../../../src/templates/paywalls.jsx" /* webpackChunkName: "component---src-templates-paywalls-jsx" */),
  "component---src-templates-persona-jsx": () => import("./../../../src/templates/persona.jsx" /* webpackChunkName: "component---src-templates-persona-jsx" */),
  "component---src-templates-platform-jsx": () => import("./../../../src/templates/platform.jsx" /* webpackChunkName: "component---src-templates-platform-jsx" */),
  "component---src-templates-pricing-for-testing-v-3-jsx": () => import("./../../../src/templates/pricing-for-testing-v3.jsx" /* webpackChunkName: "component---src-templates-pricing-for-testing-v-3-jsx" */),
  "component---src-templates-pricing-v-4-jsx": () => import("./../../../src/templates/pricing-v4.jsx" /* webpackChunkName: "component---src-templates-pricing-v-4-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-receipt-checker-jsx": () => import("./../../../src/templates/receipt-checker.jsx" /* webpackChunkName: "component---src-templates-receipt-checker-jsx" */),
  "component---src-templates-reliability-jsx": () => import("./../../../src/templates/reliability.jsx" /* webpackChunkName: "component---src-templates-reliability-jsx" */),
  "component---src-templates-report-custom-jsx": () => import("./../../../src/templates/report-custom.jsx" /* webpackChunkName: "component---src-templates-report-custom-jsx" */),
  "component---src-templates-report-jsx": () => import("./../../../src/templates/report.jsx" /* webpackChunkName: "component---src-templates-report-jsx" */),
  "component---src-templates-request-a-migration-plan-jsx": () => import("./../../../src/templates/request-a-migration-plan.jsx" /* webpackChunkName: "component---src-templates-request-a-migration-plan-jsx" */),
  "component---src-templates-request-demo-jsx": () => import("./../../../src/templates/request-demo.jsx" /* webpackChunkName: "component---src-templates-request-demo-jsx" */),
  "component---src-templates-static-jsx": () => import("./../../../src/templates/static.jsx" /* webpackChunkName: "component---src-templates-static-jsx" */),
  "component---src-templates-streaming-jsx": () => import("./../../../src/templates/streaming.jsx" /* webpackChunkName: "component---src-templates-streaming-jsx" */),
  "component---src-templates-stripe-jsx": () => import("./../../../src/templates/stripe.jsx" /* webpackChunkName: "component---src-templates-stripe-jsx" */),
  "component---src-templates-sub-club-jsx": () => import("./../../../src/templates/sub-club.jsx" /* webpackChunkName: "component---src-templates-sub-club-jsx" */),
  "component---src-templates-subscription-app-glossary-jsx": () => import("./../../../src/templates/subscription-app-glossary.jsx" /* webpackChunkName: "component---src-templates-subscription-app-glossary-jsx" */),
  "component---src-templates-support-team-jsx": () => import("./../../../src/templates/support-team.jsx" /* webpackChunkName: "component---src-templates-support-team-jsx" */),
  "component---src-templates-talk-to-sales-jsx": () => import("./../../../src/templates/talk-to-sales.jsx" /* webpackChunkName: "component---src-templates-talk-to-sales-jsx" */),
  "component---src-templates-targeting-jsx": () => import("./../../../src/templates/targeting.jsx" /* webpackChunkName: "component---src-templates-targeting-jsx" */),
  "component---src-templates-thank-you-jsx": () => import("./../../../src/templates/thank-you.jsx" /* webpackChunkName: "component---src-templates-thank-you-jsx" */),
  "component---src-templates-tutorial-post-jsx": () => import("./../../../src/templates/tutorial-post.jsx" /* webpackChunkName: "component---src-templates-tutorial-post-jsx" */),
  "component---src-templates-tutorials-jsx": () => import("./../../../src/templates/tutorials.jsx" /* webpackChunkName: "component---src-templates-tutorials-jsx" */),
  "component---src-templates-web-to-mobile-jsx": () => import("./../../../src/templates/web-to-mobile.jsx" /* webpackChunkName: "component---src-templates-web-to-mobile-jsx" */),
  "component---src-templates-webinar-post-jsx": () => import("./../../../src/templates/webinar-post.jsx" /* webpackChunkName: "component---src-templates-webinar-post-jsx" */),
  "component---src-templates-webinars-jsx": () => import("./../../../src/templates/webinars.jsx" /* webpackChunkName: "component---src-templates-webinars-jsx" */),
  "component---src-templates-why-revenuecat-jsx": () => import("./../../../src/templates/why-revenuecat.jsx" /* webpackChunkName: "component---src-templates-why-revenuecat-jsx" */)
}

